<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <!-- 球球-->
      <div class="player listmain type-sxg lgx5 game-lgx5 game-sxg-rx">
        <div class="data ctop">
          <h3>连码</h3>
          <ul class="datatop">
            <li @click="selecteLianMa(i,ele)" :class="{selected:currentIndex==i}" style="width:11.1%;" v-for="(ele,i) in rowsData[0].arr" :key="ele.label">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
            </li>

          </ul>
        </div>
        <div class="data">
          <h3>点选</h3>
          <ul class="custom_ul">
            <li :class="item.selected ? 'selected' : ''" @click="setCurrentSelected(item)" v-for="item in numArr" :key="item.number">
              <ol class="td_name">
                <div :class="'blueball b'+item.label"></div>
              </ol>

            </li>

          </ul>
        </div>
      </div>
      <div class="player listmain tuoduopeng ">
        <div class="data">
          <h3>注单号码 <span>共 <em class="red">{{totalNum}}</em> 种组合 </span></h3>

          <!-- <div class="dantuo-li">
          
          </div> -->
          <div class="dantuo-sc  red">
            <div>
              <span v-for="(item,index) in zuheData" :key="index">[{{item.join(',')}}]</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 预设 -->
      <yushe style="margin-top:30px" />
      <!-- - -->
      <!-- 表格 -->
      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td :class="qiuIndex==1?'selected':''" @click="changeQiu(1)">第一球</td>
              <td :class="qiuIndex==2?'selected':''" @click="changeQiu(2)">第二球</td>
              <td :class="qiuIndex==3?'selected':''" @click="changeQiu(3)">第三球</td>
              <td :class="qiuIndex==4?'selected':''" @click="changeQiu(4)">第四球</td>
              <td :class="qiuIndex==5?'selected':''" @click="changeQiu(5)">第五球</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="ltIndex==1?'selected':''" @click="changeLutuData(1)">单双</th>
              <th :class="ltIndex==2?'selected':''" @click="changeLutuData(2)">大小</th>
              <th :class="ltIndex==3?'selected':''" @click="changeLutuData(3)">龙虎</th>
              <th :class="ltIndex==4?'selected':''" @click="changeLutuData(4)">总和单双</th>
              <th :class="ltIndex==5?'selected':''" @click="changeLutuData(5)">总和大小</th>
            </tr>
          </tbody>
        </table>

        <table colspan="0" id="lt_ds" cellspan="0" class="lttab">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>

          </tbody>
        </table>

        <!-- --- -->
      </div>
      <!--  -->

    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
/**
 * 求：组合C(m, n)，m为上标，n为下标。m选n的所有项
 * m {必传} 原始数据
 * n {必传} 当前项还需元素的个数
 * currentIndex 当前索引
 * choseArr 当前项的部分元素集合（不是完整项，是生成完整项的一个中间状态）
 * result 所有项的结果结合
 */
function cmn(m, n, currentIndex = 0, choseArr = [], result = []) {
  let mLen = m.length;
  // 可选数量小于项所需元素的个数，则递归终止
  if (currentIndex + n > mLen) {
    return;
  }
  for (let i = currentIndex; i < mLen; i++) {
    // n === 1的时候，说明choseArr在添加一个元素，就能生成一个新的完整项了。
    // debugger
    if (n === 1) {
      // 再增加一个元素就能生成一个完整项，再加入到结果集合中
      result.push([...choseArr, m[i]]);
      // 继续下一个元素生成一个新的完整项
      i + 1 < mLen && cmn(m, n, i + 1, choseArr, result);
      break;
    }
    // 执行到这，说明n > 2，choseArr还需要两个以上的元素，才能生成一个新的完整项。则递归，往choseArr添加元素
    cmn(m, n - 1, i + 1, [...choseArr, m[i]], result);
  }
  return result;
}

export default {
  name: "syx5lianma",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'lma',
      ltArr: [],
      totalNum: 0,
      zuheData: [],
      labelArr: [
        "任选二中二",
        "任选三中三",
        "任选四中四",
        "任选五中五",
        "任选六中五",
        "任选七中五",
        "任选八中五",
        "前二组选",
        "前三组选",
      ],
      currentIndex: 0,
      bzArr: [2, 3, 4, 5, 6, 7, 8, 2, 3],
      cmnnn: [2, 3, 4, 5, 5, 5, 5, 2, 3],
      bzIndex: 2,
      numArr: [
        { label: "01", selected: false, number: 1 },
        { label: "02", selected: false, number: 2 },
        { label: "03", selected: false, number: 3 },
        { label: "04", selected: false, number: 4 },
        { label: "05", selected: false, number: 5 },
        { label: "06", selected: false, number: 6 },
        { label: "07", selected: false, number: 7 },
        { label: "08", selected: false, number: 8 },
        { label: "09", selected: false, number: 9 },
        { label: "10", selected: false, number: 10 },
        { label: "11", selected: false, number: 11 },
      ],
      rowsData: [{ title: "连码", arr: [] }],
      lutu1: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu3: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu4: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu5: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    numArr: {
      immediate: true,
      deep: true,
      handler(numarr) {
        this.watchData();
      },
    },
    amount(val) {
      this.watchData();
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resArr) {
        let arr = this.filterData(resArr, 1061, 1069, this.labelArr);
        arr.forEach((item, index) => {
          item.bzNum = this.bzArr[index];
          item.cmnnn = this.cmnnn[index];
        });

        this.rowsData[0].arr = arr;
      },
    },
  },
  created() {},
  computed: {
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              // 第二球
              arr2_ds = [[]],
              arr2_dx = [[]],
              // 第三球
              arr3_ds = [[]],
              arr3_dx = [[]],
              // 第四球
              arr4_ds = [[]],
              arr4_dx = [[]],
              // 第五球
              arr5_ds = [[]],
              arr5_dx = [[]],
              // ------------------
              arr_zh = [[]],
              arr_lh = [[]],
              arr_zhds = [[]],
              arr_zhdx = [[]];
            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              // -第一球------------------------------------------------
              //  单双
              let q1 = element.codeArr[0];
              let ds1 = "";
              if (q1 == 11) {
                ds1 = "和";
              } else {
                ds1 = q1 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr1_ds, ds1);
              // 大小
              let dx1 = "";
              if (q1 == 11) {
                dx1 = "和";
              } else {
                dx1 = q1 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr1_dx, dx1);
              //  第二球--------------- ---------------------------------
              let q2 = element.codeArr[1];
              let ds2 = "";
              if (q2 == 11) {
                ds2 = "和";
              } else {
                ds2 = q1 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr2_ds, ds2);
              // 大小
              let dx2 = "";
              if (q2 == 11) {
                dx2 = "和";
              } else {
                dx2 = q2 >= 5 ? "大" : "小";
              }
              this.initlutuData(arr2_dx, dx2);
              //  第三球--------------- ---------------------------------
              let q3 = element.codeArr[2];
              let ds3 = "";
              if (q3 == 11) {
                ds3 = "和";
              } else {
                ds3 = q3 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr3_ds, ds3);
              // 大小
              let dx3 = "";
              if (q3 == 11) {
                dx3 = "和";
              } else {
                dx3 = q3 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr3_dx, dx3);
              //  第四球--------------- ---------------------------------
              let q4 = element.codeArr[3];
              let ds4 = "";
              if (q4 == 11) {
                ds4 = "和";
              } else {
                ds4 = q4 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr4_ds, ds4);
              // 大小
              let dx4 = "";
              if (q4 == 11) {
                dx4 = "和";
              } else {
                dx4 = q4 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr4_dx, dx4);
              //  第五球--------------- ---------------------------------
              let q5 = element.codeArr[4];
              let ds5 = "";
              if (q5 == 11) {
                ds5 = "和";
              } else {
                ds5 = q5 % 2 == 0 ? "双" : "单";
              }

              this.initlutuData(arr5_ds, ds5);
              // 大小
              let dx5 = "";
              if (q5 == 11) {
                dx5 = "和";
              } else {
                dx5 = q5 >= 5 ? "大" : "小";
              }

              this.initlutuData(arr5_dx, dx5);
              // 龙虎
              let lh =
                Number(element.codeArr[0]) - Number(element.codeArr[4]) > 0
                  ? "龙"
                  : "虎";
              this.initlutuData(arr_lh, lh);

              // 总和
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              // this.initlutuData(arr_zh, sum);
              // 总和单双大小
              let sumds = sum % 2 == 0 ? "双" : "单";
              let sumdx = "";
              if (sum == 30) {
                sumdx = "和";
              } else if (sum > 30) {
                sumdx = "大";
              } else {
                sumdx = "小";
              }

              this.initlutuData(arr_zhds, sumds);
              this.initlutuData(arr_zhdx, sumdx);
              // ----------------------------------------------------------
            });
            // 第一球 单双 大小 总和
            this.buquanArr(arr1_ds, this.lutu1.arr1);
            this.buquanArr(arr1_dx, this.lutu1.arr2);
            // 第二球 单双 大小 总和
            this.buquanArr(arr2_ds, this.lutu2.arr1);
            this.buquanArr(arr2_dx, this.lutu2.arr2);
            // 第三球 单双 大小 总和
            this.buquanArr(arr3_ds, this.lutu3.arr1);
            this.buquanArr(arr3_dx, this.lutu3.arr2);
            // 第四球 单双 大小 总和
            this.buquanArr(arr4_ds, this.lutu4.arr1);
            this.buquanArr(arr4_dx, this.lutu4.arr2);
            // 第五球 单双 大小 总和
            this.buquanArr(arr5_ds, this.lutu5.arr1);
            this.buquanArr(arr5_dx, this.lutu5.arr2);
            // 龙虎---------------------------------
            this.buquanArr(arr_lh, this.lutu1.arr3);
            this.buquanArr(arr_lh, this.lutu2.arr3);
            this.buquanArr(arr_lh, this.lutu3.arr3);
            this.buquanArr(arr_lh, this.lutu4.arr3);
            this.buquanArr(arr_lh, this.lutu5.arr3);

            this.buquanArr(arr_zhds, this.lutu1.arr4);
            this.buquanArr(arr_zhds, this.lutu2.arr4);
            this.buquanArr(arr_zhds, this.lutu3.arr4);
            this.buquanArr(arr_zhds, this.lutu4.arr4);
            this.buquanArr(arr_zhds, this.lutu5.arr4);

            this.buquanArr(arr_zhdx, this.lutu1.arr5);
            this.buquanArr(arr_zhdx, this.lutu2.arr5);
            this.buquanArr(arr_zhdx, this.lutu3.arr5);
            this.buquanArr(arr_zhdx, this.lutu4.arr5);
            this.buquanArr(arr_zhdx, this.lutu5.arr5);
            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    watchData() {
      let arr = this.numArr.filter((item) => item.selected);
      let selArr = [];
      let len = arr.length;
      this.totalNum = 0;
      this.zuheData = [];
      if (len < this.bzIndex) {
        this.totalNum = 0;
        this.zuheData = [];
        this.$store.commit("setXZList", []);
      } else {
        arr.forEach((item) => {
          selArr.push(item.label);
        });
        let item = this.rowsData[0].arr[this.currentIndex];
        if (len == this.bzIndex) {
          this.totalNum = 1;
          this.zuheData = [selArr];
          let obj = {
            label: selArr.join(","),
            num: selArr.join(","),
            title: item.label,
            id: item.ResultID,
            money: this.amount,
            Odds: item.Odds,
          };
          this.$store.commit("setXZList", [obj]);
        } else {
          let xiazhu = [];
          let cArr = cmn(selArr, this.bzIndex);
          cArr.forEach((ele) => {
            this.totalNum++;
            this.zuheData.push(ele);
            let obj = {
              label: ele.join(","),
              num: ele.join(","),
              title: item.label,
              id: item.ResultID,
              money: this.amount,
              Odds: item.Odds,
            };
            xiazhu.push(obj);
          });
          this.$store.commit("setXZList", xiazhu);
        }
      }
    },
  },
};
</script>
<style  scoped>
.listmain .data ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.listmain .data ul.custom_ul li .td_name {
  width: 100%;
}
.listmain div.ctop .datatop li ol {
  width: auto !important;
  max-width: none !important;
  display: block;
  border-right: none !important;
}
</style>